"use client";
import { useState } from "react";
import { signIn } from "next-auth/react";

import {
    TextInput,
    Button
} from '@mantine/core'

const EmailLogin = ({ callbackUrl, dictionary }) => {
    const [email, setEmail] = useState('')
    const updateEmail = (e) => {
        setEmail(e)
    }

    return (
        <>
            <TextInput mb={6} label="Email address" placeholder="hello@gmail.com" size="md" onChange={(e) => updateEmail(e.target.value)} />
            <Button fullWidth bg='secondary' onClick={() => signIn("email", { email: email.toLowerCase(), callbackUrl: callbackUrl || '/' })}>
                Login
            </Button>
        </>
    )
}

export default EmailLogin