"use client";
import { signOut } from "next-auth/react";
import {
    Button
} from '@mantine/core'

const SignOutButton = ({ dictionary }) => {


    return (
        <Button bg='red' onClick={signOut}>Sign Out</Button>
    )
}

export default SignOutButton