'use client'
import { useEffect, useState } from 'react';

const SwappingText = () => {
  const words = ["Agencies", "Agents", "Projects", "Developers"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 6000); // Change word every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative inline-block ">
      <div className="overflow-hidden h-8">
        <div className='absolute top-[20px] z-20 w-[400px] h-20 bg-gradient-to-b from-light to-transparent -translate-y-full'></div>
        {words.map((word, index) => (
          <div
            key={index}
            className={`absolute bottom-0 transition-transform duration-1000 ease-in-out md:text-6xl text-2xl m-0 p-0 ${
              currentWordIndex === index ? "translate-y-0 z-10 text-[#01959C]" : "-translate-y-full opacity-100 text-white duration-500"
            }`}
          >
            {word}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SwappingText;
